<template>
  <div v-loading="loading">
    <basicHeader class="shrink-0" title="个性化配置" />
    <section>
      <p class="section-title">数据统计配置</p>

      <div class="section-body">
        <div class="section-body-item">
          <label>作业批阅设置：</label>
          <el-radio-group v-model="reviewModel" @change="isChanged = true">
            <el-radio label="1">
              简单批阅<span class="mx-8px text-F2">(教师有批阅行为即可完成批阅)</span>
              <el-popover
                trigger="hover"
                width="420"
                placement="right"
                content="老师用智能笔在卷子上批注，或使用APP/平台给作业打等级，或对任意主观题判分，或有画笔批注行为，或对作业发起订正，或有标记优秀答案/典型错误行为，均视作批完这份作业"
                ><i class="iconfont icon-tishi" slot="reference"></i
              ></el-popover>
            </el-radio>
            <el-radio label="2">
              逐题精批<span class="mx-8px text-F2">(教师需对每道题进行批阅)</span>
              <el-popover
                trigger="hover"
                width="420"
                placement="right"
                content="老师完成对所有试题的判分，或在所有试题的区域内有批阅笔迹，或对所有试题有标记优秀答案/典型错误行为，才视作批完这份作业"
                ><i class="iconfont icon-tishi" slot="reference"></i
              ></el-popover>
            </el-radio>
          </el-radio-group>
        </div>

        <div class="section-body-item">
          <label>作业等级：</label>
          <div>
            <p class="text-F2 mb-12px mt-3px">作业将默认按照得分率计算等级，老师可给作业手动修改等级</p>
            <div class="flex">
              <div style="width: 560px">
                <div class="flex justify-between mb-8px">
                  <p class="T5B">等级与得分率</p>
                  <p class="text-A3" v-if="errorTxt"><i class="iconfont icon-icon_jinggao mr-5px"></i>{{ errorTxt }}</p>
                </div>
                <el-table class="score-rate-table" border :data="scoringRateList">
                  <el-table-column label="等级" prop="levelName" width="160px"></el-table-column>
                  <el-table-column label="得分率">
                    <template slot-scope="scope">
                      <template v-if="scope.$index < scoringRateList.length - 1">
                        <el-input
                          v-model.trim="scope.row.minScoringRate"
                          size="small"
                          class="mr-8px"
                          @input="onInputRate(scope.row)"
                          @change="onChangeRate(scope.row, scope.$index)"
                        />
                        %（含）~{{ scope.row.maxScoringRate }}%（{{ scope.$index === 0 ? '含' : '不含' }}）
                      </template>
                      <span v-else>{{ scope.row.minScoringRate }}%（含）~{{ scope.row.maxScoringRate }}%（不含）</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <div style="width: 560px" class="ml-60px">
                <div class="mb-8px mt-2px">
                  <span class="T5B">临界学生</span>
                  <span class="text-F2 ml-4px">临界生即作业平均得分率接近上一等级的学生</span>
                </div>
                <el-table border :data="criticalScoringRateList">
                  <el-table-column label="临界生" width="160px">
                    <template slot-scope="scope"> {{ scope.row.levelName }} 临界生 </template>
                  </el-table-column>
                  <el-table-column label="得分率">
                    <template slot-scope="scope">
                      {{ scope.row.borderlineStudentMinScoringRate }}%（含）~{{
                        scope.row.borderlineStudentMaxScoringRate
                      }}%（不含）
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="line"></div>

    <section>
      <p class="section-title">学生端功能配置</p>
      <div class="section-body">
        <div class="section-body-item">
          <label></label>
          <div>
            <div class="mb-22px">
              <span class="T5B mr-20px">青鹿作业学生端-学生书写设置</span>
              <el-switch
                v-model="studentRecognizeResult"
                active-value="1"
                inactive-value="0"
                active-text="纸笔同屏/作业详情页面，显示学生书写识别结果"
                @change="isChanged = true"
              ></el-switch>
            </div>
            <div>
              <span class="T5B mr-20px">青鹿课堂学生端-微课学习设置</span>
              <el-switch
                v-model="microLectureLearning"
                active-value="1"
                inactive-value="0"
                active-text="支持学生访问【微课学习】模块"
                @change="isChanged = true"
              ></el-switch>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="pl-148px mt-42px">
      <EButton type="btn-fill-primary" :disabled="!!errorTxt" :loading="isEditing" @click="handleSave">保存</EButton>
      <EButton @click="handleReset">恢复默认</EButton>
    </section>
  </div>
</template>

<script>
// component
import basicHeader from '@/components/backStageHeader';
import { Radio, RadioGroup, Popover, Table, TableColumn, Switch, Input } from 'element-ui';
import EButton from '@components/button/index.vue';

// api
import { getCustomConfigSettings, saveCustomConfigSettings } from '@/api/authority-manage';
import { sum } from '@/utils/common';

export default {
  components: {
    basicHeader,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Popover.name]: Popover,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Switch.name]: Switch,
    [Input.name]: Input,
    EButton,
  },
  data() {
    return {
      loading: false,
      isEditing: false, // 修改中
      isChanged: false, // 已编辑过
      reviewModel: '',

      scoringRateList: [],
      errorTxt: '',

      studentRecognizeResult: false,
      microLectureLearning: false,
    };
  },
  computed: {
    criticalScoringRateList() {
      return this.scoringRateList.slice(0, -1);
    },
  },
  methods: {
    async getSettingList() {
      this.loading = true;
      const res = await getCustomConfigSettings({
        schoolId: this.$store.getters.schoolId,
      });
      res.result.forEach((item) => {
        switch (item.configKey) {
          case 'REVIEW_MODE':
            this.reviewModel = item.configValue;
            break;
          case 'APPRAISE_SCORING_RATE_SCOPE':
            this.scoringRateList = JSON.parse(item.configValue);
            break;
          case 'SAME_SCREEN_DISPLAY_STUDENT_RECOGNIZE_RESULT':
            this.studentRecognizeResult = item.configValue;
            break;
          case 'VISIT_MICRO_LECTURE_LEARNING':
            this.microLectureLearning = item.configValue;
            break;
        }
      });
      this.checkRateValid();
      this.loading = false;
    },

    onInputRate(row) {
      let value = row.minScoringRate.replace(/[^0-9]/g, '').replace(/(^0)([0-9]+)/, '$2');
      if (Number(value) > 100) value = value.slice(0, -1);
      row.minScoringRate = value;
    },

    onChangeRate(row, index) {
      this.isChanged = true;
      let nextRow = this.scoringRateList[index + 1];
      nextRow.maxScoringRate = row.minScoringRate;

      row.borderlineStudentMaxScoringRate = row.minScoringRate;
      row.borderlineStudentMinScoringRate = sum(nextRow.maxScoringRate * 0.8, nextRow.minScoringRate * 0.2);

      let preRow = this.scoringRateList[index - 1];
      if (preRow) {
        preRow.borderlineStudentMinScoringRate = sum(row.maxScoringRate * 0.8, row.minScoringRate * 0.2);
      }
      this.checkRateValid();
    },

    checkRateValid() {
      if (this.scoringRateList.some((item) => item.minScoringRate === '')) {
        this.errorTxt = '你还有得分率未设置';
      } else if (this.scoringRateList.some((item) => Number(item.minScoringRate) >= Number(item.maxScoringRate))) {
        this.errorTxt = '左端点需小于右端点数值';
      } else {
        this.errorTxt = '';
      }
    },

    handleSave() {
      this.$message.closeAll();
      this.isEditing = true;
      saveCustomConfigSettings({
        schoolId: this.$store.getters.schoolId,
        userId: this.$store.getters.userInfo.userId,
        reviewModeValue: this.reviewModel,
        appraiseScoringRateScopeValue: JSON.stringify(this.scoringRateList),
        displayStudentRecognizeResultValue: this.studentRecognizeResult,
        visitMicroLectureLearningValue: this.microLectureLearning,
        restoreDefault: false,
      })
        .then(() => {
          this.$message.success('保存成功');
          this.isChanged = false;
        })
        .finally(() => {
          this.isEditing = false;
        });
    },

    handleReset() {
      this.$msgbox
        .confirm(`确认恢复默认设置？`, '提示', {
          customClass: 'ql-message-box_notitle',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          saveCustomConfigSettings({
            schoolId: this.$store.getters.schoolId,
            userId: this.$store.getters.userInfo.userId,
            restoreDefault: true,
          }).then(() => {
            this.getSettingList();
            this.$message.success('已恢复默认');
            this.isChanged = false;
          });
        })
        .catch(() => {});
    },
  },

  mounted() {
    this.getSettingList();
  },

  async beforeRouteLeave(to, from, next) {
    if (!this.isChanged) {
      next();
    } else {
      setTimeout(() => {
        this.$msgbox
          .confirm('业务配置还未保存，确定要离开吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then(() => {
            next(true);
          })
          .catch(() => {
            next(false);
          });
      }, 0);
    }
  },
};
</script>

<style lang="scss" scoped src="./index.scss"></style>
