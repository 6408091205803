<template>
  <header
    class="backStageHeader flex justify-between items-center border-solid border-b border-A7 -mx-24px px-24px mb-20px"
  >
    <p class="backStageHeader-title" v-if="isShowTitle">
      {{ title }}<span v-if="subTitle" class="backStageHeader-subTitle">{{ subTitle }}</span>
    </p>

    <slot></slot>
  </header>
</template>

<script>
export default {
  name: 'BackStageHeader',

  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    isShowTitle: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.backStageHeader {
  min-height: 64px;
  flex-wrap: wrap;

  &-title {
    min-width: 80px;
    @apply text-F1 T3B;
  }

  &-subTitle {
    @apply text-F2 T6;
    padding-left: 6px;
  }

  &::v-deep {
    .icon-tianjia {
      @apply text-12;
      margin-right: 3px;
    }
    .el-button + .el-button {
      margin-left: 12px;
    }
  }
}
</style>
